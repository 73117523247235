import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "timetable-data"
    }}>{`Timetable Data`}</h1>
    <p>{`Entur operates the national registry for timetable data for all public transport in Norway, collecting data from 60 public transportation operators.
This data is open and free of use for app and service developers.`}</p>
    <h1 {...{
      "id": "netex-publications"
    }}>{`NeTEx Publications`}</h1>
    <p>{`All timetable data is exported daily to `}<a parentName="p" {...{
        "href": "/stops-and-timetable-data"
      }}>{`NeTEx files`}</a>{` that are freely available under NLOD license. This is the preferred way to consume timetable data.`}</p>
    <h1 {...{
      "id": "api"
    }}>{`API`}</h1>
    <p>{`Please refer to `}<a parentName="p" {...{
        "href": "/timetable-import-info-v1"
      }}>{`the API page`}</a>{` for API documentation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      